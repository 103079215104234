<template>
    <div class="demo-spin-article">
        <div class="col-md-12 mb-3">
            <label
                for="file"
                class="tw-border tw-p-2 tw-rounded-md tw-cursor-pointer required"
                >Browse File</label
            >
            <input
                type="file"
                ref="file"
                id="file"
                class="tw-hidden"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                @change="onFileChange"
                :class="{
                    'is-invalid': errors.has('file')
                }"
            />
            <div class="invalid-feedback" v-if="errors.has('file')">
                {{ errors.first('file') }}
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                {{ display_name }}
            </div>
        </div>
        <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3">
            <ts-button @click.prevent="() => $emit('cancel')" class="btn-gray">
                {{ $t('cancel') }}
            </ts-button>
            <ts-button
                color="primary"
                :waiting="waiting"
                :disabled="waiting"
                @click.prevent="onSave"
            >
                {{ $t('import') }}
            </ts-button>
        </div>
    </div>
</template>
<script>
import { Errors } from 'form-backend-validation'
export default {
    data () {
        return {
            errors: new Errors(),
            display_name: null,
            waiting: false
        }
    },
    methods: {
        onFileChange (e) {
            this.display_name = null
            this.file = e.target.files[0]
            this.display_name = this.file ? this.file.name : null
        },
        async onSave () {
            this.errors = new Errors()
            this.waiting = true
            let formData = new FormData()
            formData.append('file', this.file)
            this.$store
                .dispatch(
                    'corporate/citizenshipStatistic/importExcel',
                    formData
                )
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        }
    }
}
</script>
