var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('citizenshipStatistic.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('citizenshipStatistic.pageTitle'),
                active: true
            }
        ]}}),_c('ts-panel',[_c('ts-panel-wrapper',{staticClass:"tw-flex tw-items-center tw-justify-between"},[_c('div',{staticClass:"tw-space-x-2"},[_c('Button',{attrs:{"type":"success","ghost":"","loading":_vm.exporting},on:{"click":function($event){$event.preventDefault();return _vm.exportExcel.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-file-excel"}),_vm._v(" "+_vm._s(_vm.$t('export'))+" ")]),_c('Button',{attrs:{"type":"info","ghost":""},on:{"click":function($event){$event.preventDefault();return _vm.importExcel.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-file-import"}),_vm._v(" "+_vm._s(_vm.$t('import'))+" ")])],1),_c('div',{staticClass:"tw-justify-end tw-space-x-2"},[_c('Input',{style:(_vm.width),attrs:{"search":"","placeholder":_vm.$t('citizenshipStatistic.search')},on:{"on-focus":function () { return (_vm.width = 'width: 500px'); },"on-blur":function () { return (_vm.width = 'width: 300px'); }},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.resources,"size":"small","loading":_vm.loading,"max-height":"700","stripe":""},scopedSlots:_vm._u([{key:"province",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.locale == 'kh' ? row.province_name_kh : row.province_name_en)+" ")]}},{key:"district",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.locale == 'kh' ? row.district_name_kh : row.district_name_en)+" ")]}},{key:"commune",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.locale == 'kh' ? row.commune_name_kh : row.commune_name_en)+" ")]}},{key:"commune_chief_name",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.commune_chief_name)+" ")]}},{key:"commune_phone_number",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.commune_phone_number)+" ")]}},{key:"village",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.locale == 'kh' ? row.village_name_kh : row.village_name_en)+" ")]}},{key:"village_chief_name",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.village_chief_name)+" ")]}},{key:"village_phone_number",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.village_phone_number)+" ")]}},{key:"number_of_family",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.number_of_family)+" ")]}},{key:"total_population",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.total_male + row.total_female)+" ")]}},{key:"total_male",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.total_male)+" ")]}},{key:"total_female",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.total_female)+" ")]}}])}),_c('Modal',{attrs:{"draggable":"","sticky":"","scrollable":"","mask":false,"footer-hide":true,"z-index":1022,"title":_vm.$t('citizenshipStatistic.importCitizenshipStatistic')},model:{value:(_vm.showFormImport),callback:function ($$v) {_vm.showFormImport=$$v},expression:"showFormImport"}},[_c('ImportCitzenshipStatic',{ref:"import_allowance",on:{"cancel":function () { return (_vm.showFormImport = false); },"fetchData":_vm.fetchData}})],1),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-end tw-p-4"},[_c('ts-i-pagination',{on:{"navigate":function($event){return _vm.fetchData({
                        filter: {
                            has_contract: _vm.has_contract
                        }
                    })}},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }